import React, {useRef} from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { Wrapper } from '../components/Wrapper'
import { colorLightGray } from '../components/Variables/Variables'
import Btn from '../components/Button/Btn'
import CareersData from '../data/careers.json'
import { navigate } from '@reach/router'

const ApplicationStyled = styled.div`
    .form-box {
        max-width: 720px;
        margin: 50px auto;
    }
    .form-group {
        margin-bottom: 30px;
        label {
            display: block;
            margin-bottom: 12px;
        }
        &.submit-btn {
            border-top: 1px solid ${colorLightGray};
            padding-top: 35px;
            button {
                float: right;
            }
            &::after {
                content: "";
                display: table;
                clear: right;
            }
        }
    }
    .form-control {
        width: 100%;
        height: 50px;
        padding: 10px;
        border: 1px solid ${colorLightGray};
    }
`

const ApplicationBannerStyled = styled.div`
    background: url(${require('../images/application.jpg')}) 50% 20%/cover no-repeat;
    height: 320px;
    color: white;
    display: flex;
    align-items: center;
    h1 {
        font-size: 64px;
    }
`
const ApplicationBanner = (props) => {
    return (
      <ApplicationBannerStyled>
          <Wrapper>
                <h1>Lorem Ipsum Job</h1>
          </Wrapper>
      </ApplicationBannerStyled>
    )
}

const Application = () => {
    //const fileRef = useRef();
    const submit = (e) => {
        e.preventDefault();
        //console.log(fileRef.current.files[0]);
    }
    return (
        <Layout>
            <ApplicationStyled>
                <ApplicationBanner/>
                <Wrapper>
                    <div className="form-box">
                        <form>
                            <div className="form-group">
                                <label>First Name</label>
                                <input name="c_fname" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input name="c_lname" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input name="c_email" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Mobile No.</label>
                                <input name="c_phone" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label>Attach CV</label>
                                <input type="file" name="c_resume" />
                            </div>
                            <div className="form-group submit-btn">
                                <Btn primary>Submit Application</Btn>
                            </div>
                        </form>
                    </div>
                </Wrapper>
            </ApplicationStyled>
            
        </Layout>
    )
}

export default Application;